<template>
  <NuxtLayout name="dashboard">
    <div class="flex justify-between items-center">
      <p class="main-title">
        {{ team?.name }}
      </p>
      <UButton size="xl" @click="createSpellInTeam"> New Spell</UButton>
    </div>
    <UAvatarGroup size="sm" :max="2" class="mt-8">
      <div v-for="member in teamMembers" :key="member.id">
        <UTooltip :text="member.user?.profile?.fullName">
          <UAvatar :alt="member.user?.profile?.fullName" size="sm" />
        </UTooltip>
      </div>
    </UAvatarGroup>
    <UTooltip
      v-if="can('addMember', subject('Team', team))"
      text="Add members"
      :popper="{ placement: 'right' }"
    >
      <UButton
        icon="i-ph-user-plus"
        class="text-gray-400 mb-[-24px]"
        variant="ghost"
        @click="openCreateMemberModal"
      />
    </UTooltip>
    <div class="flex justify-between">
      <span v-if="teamMembers?.length > 1" class="dimmed text-sm">
        {{ teamMembers?.length }} members
      </span>
      <span v-else> </span>

      <span v-if="spellCount > 1" class="dimmed text-sm">
        {{ spellCount }} spells
      </span>
    </div>
    <hr class="mt-8 mb-8" />

    <div v-if="spellCount > 0" class="spell-grid">
      <app-spell-card
        v-for="spell in teamSpells"
        :key="spell.id"
        :spell="spell"
      />
    </div>
    <div v-else-if="isFetching" class="spell-grid">
      <USkeleton v-for="i in 15" :key="i" class="h-56 w-[22rem] rounded-xl" />
    </div>
    <div v-else>
      <empty-state icon-name="i-ph-magic-wand">
        <p class="text-lg font-bold">No spells yet</p>
        <p class="dimmed">
          Create your first spell from scratch or start with our templates
        </p>
        <div class="flex py-s gap-4">
          <UButton
            icon="i-ph-stack"
            type="button"
            size="xl"
            color="white"
            @click="navigateTo('/templates')"
          >
            See Templates
          </UButton>
          <UButton
            icon="i-ph-plus"
            type="button"
            size="xl"
            @click="createSpellInTeam"
          >
            New Spell
          </UButton>
        </div>
      </empty-state>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { subject } from '@casl/ability';
import CreateMemberModal from '~/components/modals/CreateMemberModal.vue';
import EmptyState from '~/components/table/EmptyState.vue';
import { useAppAbility } from '~/composables/useAppAbility';
import { useSpellsStore } from '~/stores/spells';
import { useWorkspaceStore } from '~/stores/workspaces';

const ability = useAppAbility();
const { can } = ability;

const workspaceStore = useWorkspaceStore();
const spellStore = useSpellsStore();

const modal = useModal();

const { team, teamMembers } = storeToRefs(workspaceStore);
const { teamSpells } = storeToRefs(spellStore);
const teamId = useRouteParams('teamId');

const { error: teamError } = await useAsyncData(
  () => workspaceStore.loadTeam(teamId.value as string),
  {
    watch: [teamId],
    immediate: !!teamId.value,
  },
);

const { pending: isFetching } = await useAsyncCache(
  `teamSpells/${teamId.value}`,
  () => spellStore.loadTeamSpells(teamId.value as string),
  {
    watch: [teamId],
    immediate: !!teamId.value,
    lazy: true,
  },
);

watchImmediate(teamError, () => {
  if (teamError.value) {
    throw new Error(teamError.value);
  }
});

const spellCount = computed(() => teamSpells.value?.length);

const createSpellInTeam = () => {
  spellStore.createSpell({ teamId: team.value?.id as string });
};

const openCreateMemberModal = () => {
  modal.open(CreateMemberModal);
};
</script>
